"use client";

import { SessionProvider } from "next-auth/react";
import { Toaster } from "sonner";
import { ModalProvider } from "@/components/modal/provider";
import { BottomDrawerStateProvider } from "@/components/contexts/BottomDrawerState";
import { MixpanelProvider } from "@/components/contexts/MixpanelContext/MixpanelContext";
export function Providers({
  children
}: {
  children: React.ReactNode;
}) {
  return <SessionProvider data-sentry-element="SessionProvider" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">
        <BottomDrawerStateProvider data-sentry-element="BottomDrawerStateProvider" data-sentry-source-file="providers.tsx">
          <Toaster className="dark:hidden" data-sentry-element="Toaster" data-sentry-source-file="providers.tsx" />
          <Toaster theme="dark" className="hidden dark:block" data-sentry-element="Toaster" data-sentry-source-file="providers.tsx" />
          <MixpanelProvider data-sentry-element="MixpanelProvider" data-sentry-source-file="providers.tsx">
            <ModalProvider data-sentry-element="ModalProvider" data-sentry-source-file="providers.tsx">
              {children}
            </ModalProvider>
          </MixpanelProvider>
        </BottomDrawerStateProvider>
    </SessionProvider>;
}