import React, { createContext } from 'react';
export const BottomDrawerStateContext = createContext({
  drawerOpen: false,
  setDrawerOpen: (open: boolean) => {}
});
export const BottomDrawerStateProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  return <BottomDrawerStateContext.Provider value={{
    drawerOpen,
    setDrawerOpen
  }} data-sentry-element="unknown" data-sentry-component="BottomDrawerStateProvider" data-sentry-source-file="BottomDrawerState.tsx">
            {children}
        </BottomDrawerStateContext.Provider>;
};